.topo{
	border-bottom:5px solid $theme2;

	.bg-menu{
		position:fixed;
		top:0;
		left:0;
		width:100%;
		opacity:0;
		background-color:rgba(#000,0.5);
		transition:opacity 0.6s linear;
	}

	.nav-content{
		background-color:#202833;
		position:relative;

		.btn{

			@include media-breakpoint-down(md){
				box-shadow:none;
			}

			&.btn-pesquisas{
				border:none;
				padding:0;
				box-shadow:none;
			}

		}
		
		@include media-breakpoint-down(md){
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
			align-items:center;
			padding-left:15px;
			padding-right:15px;
		}

		@include media-breakpoint-up(lg){
			padding-top:7px;
			padding-bottom:7px;
		}

		& > *{
			position:relative;
			z-index:50;
		}

		&:before{
			content:'';
			display:block;
			position:absolute;
			top:0;
			left:-100vw;
			width:200vw;
			height:100%;
			opacity:1;
			@include gradient-x(#2B3443,#5d626c,40%);
			// @include gradient-x(red,blue,50%);
		}

		@include media-breakpoint-up(lg){
			@include make-container;
			@include make-container-max-widths();
			display:flex;
			align-items:center;
			justify-content:space-between;
			flex-wrap:wrap;
		}

		.botao-menu{
			z-index:auto;

			.btn{
				display:flex;
				align-items:center;
				color:#FFF;
				font-family: 'lato-bold';
				box-shadow:none;
				padding:0;
				border:none;

				.bar{
					position:relative;
					width:30px;
					background-color:#FFF;
					height:7px;
					border-radius:30px;
					display:inline-block;
					margin-right:9px;

					&,
					&:before,
					&:after{
						transition:all 0.6s linear;
					}

					@include beforeAfter{
						content:'';
						display:block;
						width:100%;
						height:100%;
						background-color:inherit;
						border-radius:inherit;
						position:absolute;
						left:0;
					}

					&:before{
						bottom:calc(100% + 5px);
					}

					&:after{
						top:calc(100% + 5px);
					}

				}

				.texto{
					
					@include media-breakpoint-down(md){
						text-indent:-100vw;
					}

				}

			}

		}

		.logo-desktop{

			@include media-breakpoint-down(md){
				max-width:66px;
				padding:10px;
			}

		}

		.utilisties-topo{

			& > *{
				@include margin(0,6);
				
				@include media-breakpoint-up(lg){
					@include margin(0,15);
				}

			}

			.box-carrinho{
				display:inline-block;

				@include media-breakpoint-up(sm){
					min-width:85px;
				}

			}

			.carrinho-icone{
				position:relative;

				.circulo{
					display:none;
					
					@include media-breakpoint-up(sm){
						font-size:14px;
						width:(43 / 14) * 1em;
						height:(43 / 14) * 1em;
						line-height:(43 / 14) * 1em;
						position:absolute;
						top:-4px;
						text-align:center;
						left:calc(100% + 10px);
						color:#333;
						border-radius:100%;
						background-color:#FFF;
						transition:all 0.6s linear;
						display:block;
					}

				}

				&:focus{

					.circulo{
						box-shadow:0 0 0 4px rgba(#FFF,0.5);
					}

				}

			}

			.btn-logado{
				position:relative;
				border:none;
				box-shadow:none;
				padding:0;

				&[aria-expanded="true"]{

					&:before{
						content:'';
						display:block;
						position:absolute;
						left:50%;
						transform:translateX(-50%);
						top:calc(100% + 8px);
						border:11px solid transparent;
						border-bottom-color:#d3363a;
						z-index:400;
					}

				}

				.menu-collapse{
					position:absolute;
					text-align:left;
					list-style-type:none;
					font-family: 'latomedium';
					padding-left:0;
					margin-bottom:0;
					top:calc(100% + 30px);
					left:0;
					background-color:#FFF;
					transform:translateX(-50%);
					min-width:258px;
					z-index:600;
					font-size:13px;
					box-shadow:0 0 8px rgba(#000,0.5);

					@include media-breakpoint-up(md){
						left:50%;
					}

					li{
						padding-left:10px;
						padding-right:10px;

						&.li-header,
						&.li-footer{
							font-family: 'latoblack';
						}

						&.li-header{
							padding:20px;
							background-color:#d3363a;
							color:#FFF;
						}

						&.li-footer{
							padding-top:8px;
							text-align:center;
							padding-bottom:8px;

							.btn{
								max-width:198px;
								margin-left:auto;
								margin-right:auto;
							}

						}

						& > a:not(.btn){
							display:block;
							padding:17px 20px;
							border-bottom:1px solid #ededed;
						}

						&.active,
						&:hover{

							a:not(.btn){
								color:#af1e23;
							}

						}

					}

				}

			}

		}

	}

	.parte-principal{
		position:relative;
		background-color:#2A3B55;
		color:#FFF;

		@include media-breakpoint-down(md){
			// ESTE MIXIN ESTÁ NA PASTA DE MIXINS DESTE PROJETO (_fazer-menu.scss)
			@include fazer-menu();
		}

		@include media-breakpoint-up(lg){
			@include make-container();
			@include make-container-max-widths();
			display:flex;
			align-items:center;
			justify-content:space-between;

			& > *{
				flex-grow:1;
			}

		}

		@include media-breakpoint-up(lg){

			@include beforeAfter{
				content:'';
				display:block;
				position:absolute;
				top:0;
				width:100vw;
				height:100%;
				background-color:inherit;
				z-index:-1;
			}

			&:before{
				left:100%;
			}

			&:after{
				right:100%;
			}

		}

		.menu-principal{
			list-style-type:none;
			margin-bottom:0;
			padding-left:0;
		}

		.parte-pesquisas{

			@include media-breakpoint-up(lg){
				max-width:60px;
				margin-left:auto;
				text-align:right;
				position:relative;

			}

			@include media-breakpoint-up(xl){

				&:before{
					content:'';
					display:block;
					position:absolute;
					right:100%;
					width:1px;
					height:100%;
					background-color:#212E41;
				}

			}

			& > .btn{
				border:none;
				padding:0;
				box-shadow:none;
			}

		}

	}

	&.fx{

		@include media-breakpoint-down(md){

			.nav-content{
				position:fixed;
				top:0;
				left:0;
				width:100%;
				z-index:300;
				animation:fadeInDown 0.6s linear;
				border-bottom:5px solid $theme2;
			}

		}

	}

}

body{

	&.sombra{

		.bg-menu{
			height:100%;
			opacity:1;
			z-index:600;
		}

	}

	&.menu-active{

		.topo{

			.botao-menu{

				.btn{

					.bar{
						transform:rotate(45deg);

						&:before{
							transform:rotate(-90deg);
							bottom:0;
						}

						&:after{
							transform:rotate(90deg);
							top:0;
						}

					}

				}

			}

			.menu-topo,
			.parte-principal{
				right:0;
			}

		}

	}

}