.card-vantagens{
	max-width:190px;
	text-align:center;
	margin-bottom:12px;
	cursor:pointer;

	@include media-breakpoint-down(md){
		margin-bottom:35px;
	}

	&,
	& .figure-header,
	& .figure-header icon-awesome{
		margin-left:auto;
		margin-right:auto;
	}

	.figure-header{
		height:80px;
		width:80px;
		display:flex;
		align-items:center;
		justify-content:center;
		flex-wrap:wrap;
		background-color:#ededed;
		border-radius:100%;
		margin-bottom:15px;

		&,
		.icon-awesome{
			transition:background-color 0.6s linear;
		}

		icon-awesome{
			width:58px;
			height:58px;
			display:block;
			position:relative;

			.icon-awesome{
				width:100%;
				height:100%;
				position:absolute;
				top:0;
				left:0;
				background-color:$theme;
			}

		}

	}

	.content-card{

		.title{
			font-size:18px;
			font-family: 'latoblack';
			color:$theme;
			transition:color 0.6s linear;
			line-height:1;
		}

		.desc{
			color:#777;
			font-size:13px;
		}

	}

	&:hover{

		.figure-header{
			background-color:$theme2;

			icon-awesome{

				.icon-awesome{
					background-color:#FFF;
				}

			}

		}

		.content-card{

			.title{
				color:$theme2;
			}

		}

	}

}