.menu-principal{

	@include media-breakpoint-down(md){
		// ESTE MIXIN ESTÁ NA PASTA DE MIXINS DESTE PROJETO (_fazer-texto-before-menu.scss)
		@include fazer-texto-before-menu('CATEGORIAS');
	}

	@include media-breakpoint-up(lg){
		display:flex;
		align-items:center;
		flex-wrap:wrap;
		max-width:1030px;
		margin-right:auto;
		position:relative;
	}

	li{
		transition:all 0.6s linear;

		.item{

			a{
				display:block;
				font-family: 'lato-bold';

				@include media-breakpoint-up(lg){
					padding:21px 10px;
				}

				img{
					margin-right:2px;
				}

			}

		}

		&.active,
		&:hover{

			.item{

				a{
					background-color:$theme2;
				}

			}

		}

	}

	@include media-breakpoint-down(md){
		// ESTE MIXIN ESTÁ NA PASTA DE MIXINS DESTE PROJETO (_fazer-li-responsivo-menu.scss)
		@include fazer-li-responsivo-menu();
	}

}