.card-destaques{
	max-width:254px;
	margin-left:auto;
	margin-right:auto;
	text-align:center;
	margin-bottom:58px;
	
	.figure-header{
		min-height:254px;
		display:flex;
		align-items:center;
		flex-wrap:wrap;
		justify-content:center;
		width:100%;
		border:1px solid #EEEEEE;
		margin-bottom:6px;
		position:relative;

		.controls-plus,
		.controls-desconto{
			position:absolute;
			right:0;
		}

		.controls-plus{
			bottom:12px;
			background-color:#FFF;
			border-radius:100%;
			color:#777777;
			right:8px;
			font-size:14px;
			width:(42 / 14) * 1em;
			height:(42 / 14) * 1em;
			display:flex;
			align-items:center;
			justify-content:center;
			box-shadow:0 0 8px rgba(#000,0.5);

			@include media-breakpoint-up(lg){
				opacity:0;
				transition:opacity 0.6s linear;
			}

		}

	}

	.content-card{
		border-bottom:1px solid #ededed;
		margin-bottom:10px;

		.title{
			font-size:14px;
			color:$theme;
			font-family: 'lato-bold';
			transition:color 0.6s linear;
			margin-bottom:10px;
		}

	}

	.valor{
		font-family: 'latoblack';
		color:#777;
		margin-bottom:8px;

		s{
			font-family: 'latoregular';
		}

	}

	.btn{
		font-size:13px;
		font-family: 'lato-bold';

		.fas{
			font-size:22px;
			vertical-align:bottom;
		}

	}

	&:hover{

		.figure-header{

			.controls-plus{
				opacity:1;
			}

		}

		.content-card{

			.title{
				color:$theme2;
			}

		}

	}

}