.form-redondo{

	form{

		.input-group,
		.form-group{
			background-color:#FFF;
			border-radius:30px;

			.form-control{
				@include sombra(transparent);
				background-color:transparent;

				&:focus{
					box-shadow:none;
				}

			}

			.input-group-prepend{

				.btn{
					border-radius:30px;
				}

			}

		}

	}

}

.form-tipo-1{

	form{

		.input-group,
		.form-group{
			margin-bottom:10px;

			.form-control{
				padding-left:30px;
				min-height:42px;
				background-color:#EDEDED;
				border-color:#EDEDED;
				@include place(#323D54);
				@include sombra(#EDEDED);
				border-radius:0;
				font-size:14px;
				font-family: 'latomedium';
			}

			textarea.form-control{
				resize:none;
				padding-top:12px;
			}

			label{
				@include sr-only();
			}

		}

	}

}

.form-newsletter{

	form{

		.input-group,
		.form-group{

			@include media-breakpoint-down(md){
				display:block;
				background-color:transparent !important;
				text-align:center;
			}

			.form-control{
				min-height:50px;
				border:none;
				font-family: 'latoitalic';
				font-size:14px;
				@include place(#777);
				box-shadow:none;
				padding-left:32px;

				@include media-breakpoint-down(md){
					width:100%;
					@include sombra(#FFF);
					background-color:#FFF;
					max-width:100%;
					margin-bottom:20px;
					border-radius:30px;
				}

			}

			.input-group-prepend{
				
				@include media-breakpoint-down(md){
					min-height: 50px;
					justify-content:center;
				}

			}

		}

	}

}