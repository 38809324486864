.rodape{
	color:#FFF;

	.btn-irtopo{
		font-size:13px;

		.fas{
			font-size:23px;
			margin-bottom:5px;
		}

	}

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.title-rodape{
		font-size:14px;
		font-family: 'latoblack';
		margin-bottom:8px;
	}

	.menu-rodape{
		list-style-type:none;
		padding-left:0;
		margin-bottom:0;

		li{

			&.active > a,
			&:hover  > a{
				color:$theme2;
			}

		}

	}

	.rodape-newsletter{
		background-color:$theme;

		.container{
			padding-top:36px;
			padding-bottom:66px;
			position:relative;

			@keyframes indo{

				0%{
					transform:translateY(0);
				}

				100%{
					transform:translateY(-4px);
				}

			}

			.btn-irtopo{
				display:none;
				box-shadow:none;

				.fas{
					transition:transform 0.6s linear;
				}

				&:hover{

					.fas{
						animation:indo 1s infinite alternate;
					}

				}
				
				@include media-breakpoint-up(lg){
					display:block;
					position:absolute;
					left:100%;
					bottom:calc(100% + 6px);
					padding:0;
					border:none;
				}

			}

		}

	}

	.rodape-principal,
	.rodape-copyright{
		background-color:#252e40;
	}

	.rodape-principal{
		background-repeat:no-repeat;
		background-position:center top;

		@include media-breakpoint-down(md){
			padding-top:20px;
		}

		.container{
			position:relative;
			padding-bottom:12px;
			border-bottom:1px solid rgba($theme,10%);

			@include media-breakpoint-up(lg){
				padding-top:132px;
			}

		}

		.row-before{
			position:relative;

			& > *{

				@include media-breakpoint-down(md){
					margin-bottom:30px;
				}

			}

			@include media-breakpoint-up(lg){
				position:absolute;
				left:0;
				top:-66px;
				width:100%;
				margin-bottom:0 !important;

				&:before{
					content:'';
					display:block;
					position:absolute;
					top:50%;
					transform:translateY(-50%);
					left:40px;
					background-color:$theme2;
					width:100vw;
					height:80px;
				}

			}

		}

		.text{
			font-family: 'latoblack';
		}

		.bg-redes-sociais{
			background-color:$theme2;

			@include media-breakpoint-down(md){
				position:relative;
				padding-top:15px;
				padding-bottom:15px;

				@include beforeAfter{
					content:'';
					display:block;
					position:absolute;
					top:0;
					background-color:inherit;
					width:100vw;
					height:100%;
				}

				&:before{
					left:100%;
				}

				&:after{
					right:100%;
				}

			}

		}

	}

	.rodape-copyright{
		font-size:10px;
		font-family: 'open_sanslight';
		padding-top:14px;

		.img-gv8{
			max-width:44px;
			margin-left:6px;
		}		

	}

}