.resumo-compra{
	
	.resumo-compra-header{
		padding: 15px;
		background: $theme;
		color: #FFF;
		font-size: 16px;
		font-family: 'latoblack';
	}

	.resumo-compra-corpo{
		padding: 13px 11px 11px 11px;
		background: #FFF;
		border:2px solid #F0F0F0;
	}

	.card{
		font-size:13px;
		color:#333;
		border:none;

		.card-body{
			padding:9px 13px;
		}

	}

	.card,
	.card > *{
		border-radius:0;
	}

	.card:last-child{
		border:none;
		background-color:transparent;
	}

	.table{
		margin-bottom:0;

		.total{
			font-size:16px;
		}

		th{
			font-weight:normal;
		}

		th,
		td{
			border:none;
		}

		thead{
			background: #EDEDED;
			font-weight: 300;

			th ~ th{
				text-align: center;
			}
		}

		thead,
		tbody{
			& > tr{
				td:not(:first-child){
					text-align: center;
				}
			}
		}

		tbody > tr > td:last-child,
		tfoot > tr > td:last-child{
			white-space: nowrap;
			text-align: right;
		}

		tbody > tr:hover > *{
			background: rgba(0,0,0,0.03);
		}

		tfoot{
			tr:nth-child(odd){
				td,
				th{
					background-color:#EDEDED;
				}
			}
		}
	}

	.tr-total,
	.card-header{
		border:1px solid #D6D6D6;
		border:none;
	}

	.card-header{
		font-size: 16px;
		padding:6px 12px;
		font-family: 'latoblack';
		border-radius:0;
		background-color: #EDEDED;
		border:none;
		border-bottom:1px solid #FFF;
	}

	.tr-total{
		font-family:inherit;
	}

	.total{
	}

	.label-total,
	.label-total ~ *{
		font-family: 'lato-bold';	
	}

}