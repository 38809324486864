.article-promocoes{

	@include media-breakpoint-up(lg){
		text-align:left;
	}

	.row{

		& > *{
			min-height:481px;
		}

		.col-cinza{
			background-color:$theme;
			color:#FFF;
			position:relative;

			@include media-breakpoint-up(lg){
				padding-left:30px;
				padding-right:30px;
			}

			.header-section{
				padding-top:20px;
			}

			@include beforeAfter{
				
				@include media-breakpoint-up(lg){
					content:'';
					display:block;
					position:absolute;
					left:0;
					background-color:inherit;
					width:100%;
					height:20px;
				}

			}

			&:before{
				top:100%;
			}

			&:after{
				bottom:100%;
			}

		}

		.col-branca{
			padding-top:20px;
			padding-bottom:20px;

			.figure-header{
				height:100%;
				background-color:#FFF;
				position:relative;
				display:flex;
				align-items:center;
				justify-content:center;

				.controls-circle{
					position:absolute;
					bottom:16px;
					right:16px;
					width:40px;
					height:40px;
					display:flex;
					align-items:center;
					justify-content:center;
					flex-wrap:wrap;
					border-radius:100%;
					color:#FFF;
					background-color:$theme2;
					transition:background-color 0.6s linear;

					&:hover{
						background-color:$theme;
					}

				}

				@include media-breakpoint-up(lg){

					&:before{
						content:'';
						display:block;
						width:45px;
						height:100%;
						background-color:inherit;
						position:absolute;
						top:0;
						right:100%;
					}

				}

			}

		}

	}

	.title{
		font-size:30px;
		font-family: 'lato-bold';
	}

	.border-diferente{

		.bar-principal{
			width:44px;
			display:inline-block;
			background-color:#1B2A3D;
			height:2px;
			position:relative;

			@include beforeAfter{
				content:'';
				display:block;
				position:absolute;
				width:8px;
				top:0;
				height:100%;
				background-color:inherit;
			}

			&:before{
				left:calc(100% + 6px);
			}

			&:after{
				left:calc(100% + 20px);
			}

		}

	}

}