@import "_home.scss";

.internas{
	padding-bottom:55px;

	.title-internas{
		font-size:27px;
		font-family: 'latoblack';
		color:#323D54;
		margin-bottom:46px;
	}

	.internas-breadcrumb{
		list-style-type:none;
		margin-bottom:60px;
		padding:19px 0;
		background-color:#293448;
		color:#FFF;
		text-align:right;
		font-size:12px;
		position:relative;

		@include beforeAfter{
			content:'';
			display:block;
			position:absolute;
			top:0;
			width:100vw;
			height:100%;
			background-color:inherit;
		}

		&:before{
			left:100%;
		}

		&:after{
			right:100%;
		}

	}

	.max-content{
		max-width:930px;
		margin-left:auto;
		margin-right:auto;
	}

	&.internas-cadastro{

		.form-cadastro{
			font-size:14px;
			color:#333;

			&.pessoaJuridica{

				.juridico{
					display:block !important;
				}

				.fisica{
					display:none !important;
				}

			}

			&.pessoaFisica{


				.juridico{
					display:none !important;
				}

				.fisica{
					display:block !important;
				}

			}

			.h6{
			}

			.limitador{
				max-width:1000px;
				margin-left:auto;
				margin-right:auto;
			}

			.label-fake{
				font-size:16px;
			}

		}

		.tab-custom{

			li + li{

				@include media-breakpoint-up(lg){
					margin-left:30px;
				}

			}

		}

	}

	&.internas-finalizar-compra{

		.hr-divider-diferente{
			border-top:8px solid #EDEDED;
		}

	}

	&.internas-meus-enderecos{
		font-family: 'latomedium';
		color:#323D54;

		.endereco-atual{
			padding-bottom:25px;
		}

	}

	&.internas-meus-pedidos-detalhes{

		.bg-gray{
			background-color:#EDEDED;
			color:#323D54;
			padding:24px 15px 14px 15px;
		}

		.pedido-realizado{
			flex-grow:1;
			font-size:11px;

			.tab-custom{
				background-color:#EDEDED;
				padding:8px 16px 8px 32px;
				position:relative;
				margin-bottom:10px;
				z-index:2;
				text-align:center;
				color:#1D1D1D;

				@include beforeAfter{
					content:'';
					display:block;
					position:absolute;
					border:16px solid transparent;
					z-index:-2;
					height:100%;
				}

				&:before{
					left:100%;
					top:0;
				}

				&:after{
					border-left-color:#FFF;
					left:0;
					top:0;
				}

				&.active{
					background-color:darken($theme,0%);
					color:#fff;

					&:before{
						left:calc(100% + 1px);
						border-left-color:darken($theme,0%) !important;
					}

				}

			}

			& + .pedido-realizado{

				.tab-custom{

					&:before{
						border-left-color:#EDEDED;
					}

				}

			}

			&:first-child{

				.tab-custom{

					&:after{
						border-left-color:transparent;
					}

				}

			}

			&:last-child{

				.tab-custom{

					&:before{
						border-left-color:transparent;
					}

				}

			}

		}


	}

	&.internas-produtos-detalhes{

		.card.card-add-carrinho{
			padding:18px 12px;
		}

		lazy-image{
			display:inline-block;
		}

		.internas-content{
			padding-top:35px;
		}

		.fotos-detail-prod{

			.h5{
				color:#000000;
				font-size:12px;
				margin-bottom:22px;
			}

		}

		.title-valor{
			font-size:35px;
			line-height:1;
			margin-bottom:0;

			.text-small{
				font-size:15px;
			}

		}

		.texto-parcela{
			font-size:15px;
		}

		.rating{
			color:#4d4d4d;

			.fa-star{

				&.full{
					color:#EAA932;
				}

				&.half{
					position:relative;

					&:after{
						content:'\f089';
						display:inline-block;
						width:100%;
						height:100%;
						position:absolute;
						top:0;
						left:0;
						@extend .fas;
						color:#EAA932;
					}

				}

			}

			.fa{
				@include margin(0,2);

			}

			&.rating-diferente{

				.fas{
					color:#ffe0e1;

					&.full{
						color:darken($theme2,10%);
					}

					&.half{

						&:after{
							content:'\f089';
							display:inline-block;
							width:100%;
							height:100%;
							position:absolute;
							top:0;
							left:0;
							color:darken($theme2,10%);
						}

					}

				}

			}

		}

		.hr-divider{
			border-color:#EDEDED;
			border-width:4px;
		}

		.divider-hr{
			border-top:5px solid #ededed;
		}

		.row-imagens{

			& > *{
				@include margin(0,15);
				transition:box-shadow 0.6s linear;

				img{
					transition:border 0.6s linear;
					border:2px solid transparent;
				}

				a{
					z-index:2;
					position:relative;
					display:block;

					&.zoomGalleryActive,
					&:hover,
					&:focus{
						
						img{
							border-color:$theme;
						}

					}

				}

				@include hover-focus(){
					border-color:$theme;
				}

				&:hover{
					box-shadow:none;

					a:before{
						opacity:1;
						z-index:30;
					}

				}

			}

		}

		.media-legal{
			font-size: 40px;
			margin-bottom:15px;
			text-align:center;
			align-items:center;
			padding-bottom:0;
			border-bottom:1px solid #E3E3E3;

			.nota{
				color: darken($theme2,10%);
			}

			.rating,
			.nota{
				line-height:1;
			}

		}

		.depoimento{
			margin-bottom:12px;
			padding-bottom:20px;
			border-bottom:1px solid #E3E3E3;

			.autor{
				color:#666;
			}

			&.depoimento-peq{
				border:none;
				padding-bottom:0;
				margin-bottom:0;
			}

			&.depoimento-grande{
				font-size:30px;

				.rating{
					margin-bottom:20px;
				}

			}

			&:last-child{
				border:none;
			}

		}

		.details-prod-text{
			font-size: 16px;
			color: #383838;

			.card-body-cep{
				color:#333333;
				font-size:12px;

				.form-control{
					@include place(#8F8F8A);
					font-size:13px;
				}

				.form-control,
				.input-group-addon .btn{
					border-color:#ccc;
				}

				.fa-truck{
					margin-right:5px;
					font-size:15px;
				}

				.cep-text{
					font-size:14px;
					color:#333333;
				}

			}

			.avaliacoes{
				font-size:12px;
				align-self:center;
				color:$theme;
			}

			.card{

				.btn-theme.btn-expanded{
					font-size:18px;

					.fa{
						font-size:25px;
						margin-right:3px;
					}

				}

			}

			.nome,
			.cod{
				color:$theme;
				font-family: 'latoblack';
			}

			.nome{
				font-size: 18px;
				margin-bottom: 15px;
			}

			.cod{
				margin-bottom: 8px;
				font-size:12px;
			}

			.bg-grey{
				background: #EDEDED;
			}

			.card{
				border-radius:0;
				border:none;

				.btn-redondo{

					.fa{
						margin-right:10px;
						font-size:26px;
					}

				}

				.card-body{

					& > .row{
						align-items:center;
						margin-bottom:10px;
					}

				}

				.form-control{
					border-color:#cecece;
					min-height:44px;
					border-right:none;
					font-size:12px;
					border-radius:0;
					@include sombra(#ccc);
					@include place($theme);
					font-family: 'latomedium';
				}

				.input-group-addon{

					.btn{
						border-color:#cecece !important;
						border-left:none;
						font-size:13px;
						color:#1F1F1F;
					}

				}

				.btn-frete{
					color:#FFF;
					width:28px;
					height:28px;
					line-height:28px;
					text-align:center;
					font-size:10px;
					padding:0;
					border-radius:100%;
				}

			}

			.form-control{
				border-color: #FFF;
				font-size:12px;
				color:#8E8E89;

				@include placeholdIt{
					color:#8E8E89;
				}

			}

			.input-group{
				
				.input-group-addon{
					display:flex;

					.btn{
						font-size:13px;
						border-radius: 0;
						@include button-variant(#FFF, #FFF);
						color:#bfbfbf;

						&:hover{
							border-color: #222;
							color:#1F1F1F;
						}
					}

				}

			}

			.btn{
				white-space:normal;

				&.btn-question{
					padding:0;
					width:24px;
					height:24px;
					display:flex;
					align-items:center;
					border-radius:100%;
					justify-content:center;
				}


				.fas{
					font-size:15px;
					vertical-align:text-bottom;
				}

			}

			.card-preco{
				margin-bottom: 15px;
				display: flex;
				flex-wrap: wrap;
				text-align: center;
				font-size:14px;
				color:$theme;

				.boleto{
					font-size: 18px;
					font-family: 'latoblack';
				}

				.special{
					color: $theme;
					font-size:13px;
					font-family: 'latoblack';
					
					span{
						font-size: 20px;
					}

				}

				.cond{
					font-size:12px;
				}

				@include media-breakpoint-up(sm){
					flex-direction: row;
					align-items: center;

					.boleto{
						border-left:2px solid #d1d0d0;
					}
				}

				& > *{
					min-width: 50%;
					padding: 15px;
				}
			}

			.card-body-adicionar{

				.btn{
					padding:6px 12px;
					font-size:18px;

				}

			}

			.compartilhar{

				.h5{
					font-size:12px;
					color:#1F1F1F;
					margin-bottom:0;
				}

				a{
					display: inline-block;
					margin-right: 0.5em;
				}
			}
		}

		.avaliar{

			button,
			h2,
			.radio-custom{
			}

			h2 {
				color: #333;
				font-size: 20px;
				margin-bottom: 10px;
			}

			.subtitle{
				font-size:15px;
				font-family: 'latoblack';
				color:$theme;
				margin-bottom:15px;
			}

			.form-group{

				.subtitle{
					margin-bottom:10px;
				}

				.form-control{
					resize:none;
					background-color:#eeeeee;
					@include sombra(#EEE);
					border:none;
				}

			}

			.radio-custom{
				font-size:15px;
				color:$theme;

			}

		}

		.media-legal .badge,
		.depoimento .badge{
			display:inline-block;
		}

	}

	.modal-saber-cep{


		.modal-content{
			padding:20px;
		}

	}

	.form-cadastro-modal{
		padding:20px;

		.form-group,
		.input-group{

			.form-control{
				background-color:#ccc;
				@include sombra($theme);
				@include place(#111);
			}

		}

	}

}