.form-pesquisas-topo{
	font-family: 'latoitalic';

	form{

		.form-group{
			margin-bottom:0;

			.form-control{
				height:100%;
				background-color:transparent;
				border-color:transparent;
				border-radius:0;
				@include place(#FFF);
				@include sombra($theme2);
			}

		}

	}

	@include media-breakpoint-up(lg){
		position:absolute;
		top:-100vh;
		left:0;
		width:100%;
		height:100%;
		opacity:0;
		transition:opacity 0.6s linear;

		form{

			&,
			& > *{
				height:100%;
			}

		}

	}

	@include media-breakpoint-down(md){
		position:fixed;
		top:-100vh;
		left:0;
		transition:top 0.6s linear;
		padding-left:15px;
		padding-right:15px;
		width:100%;

		.form-group{

			.form-control{
				border-radius:0;
				min-height:50px;
				border-width:2px;
				border-color:#FFF !important;
			}

		}

	}

}

body{

	&.form-pesquisa-topo{

		.topo{

			.menu-principal{
				
				@include media-breakpoint-up(lg){

					& > li:not(.li-form){
						opacity:0;
					}

				}

			}

			.form-pesquisas-topo{
				
				@include media-breakpoint-up(lg){
					top:0;
					opacity:1;
					z-index:1000;
				}

			}

			.form-pesquisas-topo{

				@include media-breakpoint-down(md){
					top:40px;
				}

			}

		}

	}

}