.redes-sociais{

	& > *{
		font-size:16px;
		width:(25 / 16) * 1em;
		height:(25 / 16) * 1em;
		line-height:(25 / 16) * 1em;
		text-align:center;
		background-color:#FFF;
		color:$theme2;
		border-radius:100%;
		@include margin(0,7);

		@include hover-focus{
			color:$theme2;
			background-color:$theme;
		}

	}

	&.redes-sociais-internas-diferente{

		& > *{
			@include margin(0,9);
			font-size:15px;
			width:(24 / 15) * 1em;
			height:(24 / 15) * 1em;
			line-height:(24 / 15) * 1em;
			display:flex;
			align-items:center;
			justify-content:center;
			flex-wrap:wrap;
			background-color:#000;
			color:#FFF;

			&:hover{
				background-color:$theme2;
			}

		}

	}

}