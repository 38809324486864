.paginacao-custom{

	& > *{
		@include margin(0,4);
	}

	.page-item{

		.page-link{
			border-radius:100%;
			padding:0;
			border:none;
			font-size:14px;
			line-height:(20 / 12) * 1em;
			width:(20 / 14) * 1em;
			height:(20 / 14) * 1em;
			display:flex;
			align-items:center;
			flex-wrap:wrap;
			justify-content:center;
			font-family: 'latoblack';
			background-color:$theme;
			border-color:$theme;
			color:#FFF;
		}

		&.active > .page-link,
		&:hover  > .page-link{
			background-color:$theme2;
		}

	}

}