@font-face {
    font-family: 'latoblack';
    src: url('#{$fonts}/lato_black/lato-black-webfont.eot');
    src: url('#{$fonts}/lato_black/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_black/lato-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_black/lato-black-webfont.woff') format('woff'),
         url('#{$fonts}/lato_black/lato-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_black/lato-black-webfont.svg#latoblack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lato-bold';
    src: url('#{$fonts}/lato-bold/lato-bold-webfont.eot');
    src: url('#{$fonts}/lato-bold/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.woff') format('woff'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.svg#latobold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoitalic';
    src: url('#{$fonts}/lato_italic/lato-italic-webfont.eot');
    src: url('#{$fonts}/lato_italic/lato-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_italic/lato-italic-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_italic/lato-italic-webfont.woff') format('woff'),
         url('#{$fonts}/lato_italic/lato-italic-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_italic/lato-italic-webfont.svg#latoitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: url('#{$fonts}/lato_regular/lato-regular-webfont.eot');
    src: url('#{$fonts}/lato_regular/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.woff') format('woff'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanslight';
    src: url('#{$fonts}/opensans-light/opensans-light-webfont.eot');
    src: url('#{$fonts}/opensans-light/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans-light/opensans-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans-light/opensans-light-webfont.woff') format('woff'),
         url('#{$fonts}/opensans-light/opensans-light-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans-light/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latomedium';
    src: url('#{$fonts}/lato_medium/lato-medium-webfont.eot');
    src: url('#{$fonts}/lato_medium/lato-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_medium/lato-medium-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_medium/lato-medium-webfont.woff') format('woff'),
         url('#{$fonts}/lato_medium/lato-medium-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_medium/lato-medium-webfont.svg#latomedium') format('svg');
    font-weight: normal;
    font-style: normal;
}