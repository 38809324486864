.banner{
	text-align:center;

	.carousel-item{
		margin-bottom:0;
	}

	.carousel-controls{
		position:absolute;
		top:0;
		left:0;
		display:flex;
		justify-content:flex-end;
		align-items:flex-end;
		width:100%;
		height:100%;

		.clearfix{
			width:100%;

			@include media-breakpoint-down(md){
				text-align:right;
				padding:5px 15px;
			}

			@include media-breakpoint-up(lg){
				@include make-container();
				@include make-container-max-widths();
				display:flex;
				justify-content:flex-end;
				padding-bottom:200px;
			}

			& > *{
				position:relative;
				z-index:200;
				@include margin(0,5);
			}

		}

	}

}

.imagens{
	
	@include media-breakpoint-up(lg){
		margin-top:-176px;
	}

	figure{
		position:relative;
		z-index:250;
	}

}

.destaques{
	padding-top:12px;
}

.borda{
	width:100%;
	display:block;
	height:1px;
	background-color:#EEEEEE;
}

.box-controls-desconto{
	max-width:48px;
	margin-right:auto;

	@include media-breakpoint-down(md){
		margin-left:auto;
	}

}

.controls-desconto{
	background-color:$theme2;
	color:#FFF;
	top:10px;
	font-family: 'latoblack';
	padding:2px 4px;
	min-height:30px;
	display:flex;
	align-items:center;
	justify-content:center;

	&:before{
		content:'';
		display:block;
		position:absolute;
		top:50%;
		transform:translateY(-50%);
		right:100%;
		border:14px solid transparent;
		border-left-width:10px;
		border-right-width:10px;
		border-right-color:$theme2;
	}

}

.promocoes{
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;

	.container{

		.segura-slick{

			.carousel{

				.carousel-inner{

					@include media-breakpoint-down(md){
						padding-bottom:60px;
					}

				}

				.carousel-item{
					position:static !important;
				}

				.carousel-inner{
					
					@include media-breakpoint-up(lg){
						overflow:visible;
					}

				}

			}

			@include media-breakpoint-up(lg){
				max-width:972px;
				margin-left:auto;
				margin-right:auto;
				position:relative;
			}

			.controls-solo{
				position:absolute;
				bottom:0;
				left:0;

				@include media-breakpoint-down(md){
					width:100%;
					padding-bottom:20px;
				}
				
				@include media-breakpoint-up(lg){
					left:40px;
				}

				& > *{
					position:relative;
					z-index:400;
				}

			}

		}

	}

}

.vantagens{
	padding-top:62px;
	padding-bottom:23px;
}