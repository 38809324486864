.forma-pagamento{
	color:#1f1f1f;
	font-size:13px;
	
	&:first-child{
		padding-top:30px;
	}

	.title-mais-small{
		font-size:13px;
	}

	.title-small{
		font-size:16px;
		margin-bottom:0;
		font-family: 'latoblack';
	}

}