@import "_fontfaces";

body{
	color:#777777;
	font-size:14px;
	font-family: 'latoregular';
}

// FONTS USADAS
.lato-blk{
	font-family: 'latoblack';
}

.lato-bld{
	font-family: 'lato-bold';
}

.lato-itc{
	font-family: 'latoitalic';
}

.lato-reg{
	font-family: 'latoregular';
}

.lato-med{
	font-family: 'latomedium';
}

.opns-lgt{
	font-family: 'open_sanslight';
}

#app{
	position:relative;
	overflow:hidden;
}

.topo a,
.rodape a{
	word-break:break-all;
}

.logo{
	margin-bottom:0;
}

.internas img{
	@include img-fluid();
}

a,
.btn{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

lazy-image{
	background-color:transparent;
}

.slick-slider{

	img{
		display:inline-block !important;
	}

}

.carousel{
	text-align:center;

	.carousel-item{
		position: relative;
		z-index:200;
	}

}

:focus{
	outline:none !important;
}

.slick-slider{

	img{
		@include img-fluid();
	}

}

.text-272727{
	color:#272727 !important;
}

.text-black{
	color:#000 !important;
}

.text-quase-theme{
	color:#323D54 !important;
}

// concerta erro meu (João) de sprites em outros projetos
.icon{

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}

.square{
	font-size:24px;
	width:(50 / 24) * 1em;
	height:(50 / 24) * 1em;
	line-height:(50 / 24) * 1em;
	text-align:center;

	&.square-white{
		border:1px solid #FFF;
		color:#FFF;
	}

	&.square-black{
		border:1px solid #000;
		color:#000;
	}

	&.hover-theme-2{
		
		@include hover-focus{
			background-color:$theme2;
			color:#FFF;
			border-color:$theme2;
		}

	}

}

.controls-solo{

	& > *{
		@include margin(0,5);
	}

}

.fnt-14{
	font-size:14px !important;
}

.fnt-15{
	font-size:15px !important;
}

.fnt-17{
	font-size:17px !important;
}

.fnt-20{
	font-size:20px !important;
}

.fnt-24{
	font-size:24px !important;
}

.fnt-27{
	font-size:27px !important;
}

.text-theme{
	color:$theme !important;
}

.slick-slider{

	.slick-track{

		@include media-breakpoint-up(sm){
			display:flex;
			flex-wrap:wrap;
			align-items:flex-end;
		}

	}

}

.radios{
	margin-bottom:20px;
}

.title-radios{
	font-size:20px;
	color:$theme;
	font-family: 'latoblack';
	margin-bottom:10px;
}