.header-section{
	font-size:30px;
	color:$theme;
	font-family: 'latoblack';
	margin-bottom:15px;

	.title{
		font-size:1em;
		position:relative;
		line-height:1;
		margin-bottom:0;

		&:before{
			content:'';
			display:block;
			position:absolute;
			top:calc(100% + 16px);
			left:0;
			width:100%;
			height:4px;
			background-color:$theme2;
		}

	}

}