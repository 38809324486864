@mixin fazer-texto-before-menu($teste){

	&:before{
		content:$teste;
		font-size:20px;
		padding:15px 20px;
		font-family: 'lato-bold';
		display:block;
		color:#FFF;
		text-align:center;
	}

}