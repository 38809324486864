#loading-page {
  position:fixed;
  top:0;
  left:0;
  z-index:1000;
  width:100%;
  height:100%;
  background-color:$theme;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
  
  .loader{
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
  }

  &.active{
    display:flex !important;
  }

}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}