@mixin fazer-li-responsivo-menu{

	li{

		a{
			display:block;
			border-top:1px solid transparent;
			border-bottom:1px solid transparent;
			padding:12px 15px;
		}

		&.active,
		&:hover{

			& > a{
				border-color:darken($theme2,15%);
				background-color:$theme2;
			}

		}
		
	}

}