.filtrar-categorias{
	border-radius:0;
	overflow:hidden;
	border:1px solid #ededed;

	.header-filtro-categorias{
		padding: 10px 15px;
		text-align: center;
		color: #FFF;
		background: $theme;
		margin-bottom: 35px;
		width: 100%;
		font-size:19px;
		line-height:1;
		font-family: 'latoblack';
	}

	h2{
		color:$theme;
		font-size: 13px;
		font-family: 'latomedium';
		margin-bottom: 16px;
	}

	.filtro{
		margin-bottom: 30px;
		padding:5px 10px;

		&:last-child{

			&:after{
				display:none;
			}

		}

		ul{
			list-style: none;
			padding-left:0;
			margin-bottom: 0;
			font-size:13px;

			a{
				display: block;
				color:#1F1F1F;

				.badge{
					color:inherit;
					padding-left:0;
					font-size:13px;
				}

				&:hover{
					text-decoration: none;
					color: $theme;
				}
			}
		}

		.mostrar,
		.ocultar{
			color:$theme;
			font-size:14px;
		}

		a[aria-expanded="false"]{
			&.ocultar{
				display: none;
			}
		}

		a[aria-expanded="true"]{
			&.mostrar{
				display: none;
			}
		}

		.toggle-filtro{
			margin-top: 10px;
			font-size:12px;
			color:#1F1F1F;

			&:hover a{
				color:darken($theme,10%);
			}

		}
	}

	.radio-custom{

		label{
			transition:color 0.6s linear;
			color:#000;

			&:before{
				background-color:#000;
			}

		}

	}

	.options-filtro{
		max-height: 220px;
		overflow: auto;

		.form-group{
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-only(md){
		display: flex;
		flex-wrap: wrap;

		.header-filtro-categorias{
			margin-bottom: 0;
		}

		.filtro{
			width: 33.333%;
			padding: 10px;
			border-left: 1px solid rgba(#000,0.2);
			border-bottom: 1px solid rgba(#000,0.2);
			padding-top: 30px;

			&:last-child{
				border-right: 1px solid rgba(#000,0.2);
			}
		}
	}
}