.tabs-produto{
	margin-bottom:30px;
	font-size:14px;
	color:$theme;
	padding-top:12px;

	.tab-title{
		font-size:20px;
		color:$theme;
		font-family: 'lato-bold';
		margin-bottom:15px;
	}

	.nav-tab-prod{
		margin-bottom:0;
		display: flex;
		flex-wrap:wrap;
		list-style:none;
		padding-left:0;

		a{
			display: block;
			padding:5px 20px;
			background: lighten($theme,10%);
			color:#FFF;
			font-size:13px;
			min-width:122px;
			font-family: 'lato-bold';

			&:hover{
				background: $theme;
				text-decoration: none;
			}
		}

		.active a,
		.active a:hover{
			background: #ededed;
			color:#000;
			cursor: default;
		}
	}


	.tab-produto{
		background-color:#ededed;
		padding: 15px 25px;
		display: none;
		color:$theme;

		&.active {
			display: block;
			animation: fadeIn 0.3s linear;
		}
	}
}