@mixin fazer-menu{
	
	&{
		position:fixed;
		top:0;
		right:-230px;
		width:230px;
		height:100%;
		background-color:$theme;
		overflow:auto;
		z-index:1000;
		border-left:1px solid #000;
		transition:right 0.6s linear;
	}

}