.menu-topo{
	list-style-type:none;
	margin-bottom:0;
	padding-left:0;
	color:#FFF;
	font-family: 'lato-bold';

	@include media-breakpoint-down(md){
		// ESTE MIXIN ESTÁ NA PASTA DE MIXINS DESTE PROJETO (_fazer-texto-before-menu.scss)
		@include fazer-texto-before-menu('MENU');
	}

	// ESTE MIXIN ESTÁ NA PASTA DE MIXINS DESTE PROJETO (_fazer-menu.scss)
	@include media-breakpoint-up(lg){
		@include fazer-menu();
	}

	// ESTE MIXIN ESTÁ NA PASTA DE MIXINS DESTE PROJETO (_fazer-li-responsivo-menu.scss)
	@include fazer-li-responsivo-menu();

	li.dropdown{
		display:flex;
		justify-content:space-between;
		flex-wrap:wrap;

		& > a{
			flex-grow:1;
			width:80%;
		}

		& > .btn{
			width:20%;
			color:#FFF;
			display:flex;
			align-items:center;
			flex-wrap:wrap;
			border-radius:0;

			&[aria-expanded="true"]{
				background-color:$theme2;

				.fas{
					@extend .fa-minus;
				}

			}

		}

		ul{
			width:100%;
			list-style-type:none;
			padding-left:0;
			margin-bottom:0;

			li{

				a{

					&:before{
						content:'-';
						display:inline-block;
						margin-right:6px;
						vertical-align:middle;
					}

				}

			}

		}

	}

}